/* eslint eqeqeq: 0 */
import React, { Component } from 'react';
import axios from 'axios'

const validateTokenLAURL = 'https://prod-00.uksouth.logic.azure.com:443/workflows/0be8874e9cf3435a8fa35d2a86c8f68a/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=l_kb_gapS9ik8dTA5laLOZBeWSeST3TrWShslwMt304'
const sendConfirmationCodeLAURL = 'https://prod-25.uksouth.logic.azure.com:443/workflows/3e03a4aae571410bba9a06e8ff010c32/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=AeehgCXrjJmCcf-zLILWb9iQjRU5PFkAQwfRPQ2_Zus';
const validateConfirmationCodeLAURL = 'https://prod-09.uksouth.logic.azure.com:443/workflows/a6b2bc13cb3344c6bf112b3b4945524b/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=xsVgezR5H2Wq1hbw74PnCtVNUM16LxpYHOYo3G1gjpM';
const setPasswordLAURL = 'https://prod-23.uksouth.logic.azure.com:443/workflows/93f5b809a6e749aa85cf0ae8010573cb/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Hq3-MT5KeRijffVt6g0cmpMIQGnwpiOU1d3FiE6_dQY';
const queryParameters = new URLSearchParams(window.location.search)


async function lookupToken(token) {
  try {
    const response = await axios.post(validateTokenLAURL, ('{"token": "' + token + '"}'),
      { headers: { "Content-Type": "application/json; charset=UTF-8" } }); //user lookup
    return response
  } catch (error) {
    console.log("Error (lookupToken): ", error);
    return null;
  }
}

async function sendConfirmation(token) {
  try {
    const response = await axios.post(sendConfirmationCodeLAURL, ('{"token": "' + token + '"}'),
      { headers: { "Content-Type": "application/json; charset=UTF-8" } });

    return response
  } catch (error) {
    console.log("Error (sendConfirmation): ", error);
    return null;
  }
}

async function validateConfirmation(token, sms) {
  try {
    const response = await axios.post(validateConfirmationCodeLAURL, ('{"token": "' + token + '", "sms": "' + sms + '"}'),
      { headers: { "Content-Type": "application/json; charset=UTF-8" } });
    if (response.status == '200') {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error (validateConfirmation): ", error)
    return null;
  }
}

async function setPassword(token, password) {
  try {
    const response = await axios.post(setPasswordLAURL, ('{"token": "' + token + '", "password": "' + password + '"}'),
      { headers: { "Content-Type": "application/json; charset=UTF-8" } });
    if (response.status == '200') {
      return true;
    } else {
      return response.status;
    }
  } catch (error) {
    console.log("Error (validateConfirmation): ", error)
    return null;
  }
}

class App extends Component {

  constructor() {
    super();

    this.state = {
      country: '',
      isLoading: true,
      isTokenValid: false,
      isSMSValid: false,
      isField: true,
      isGuest: false,
      showSMSError: false,
      showResentSMS: false,
      inputSMS: '',
      maskedSMS: '',
      userPrincipalName: '',
      showWeakPasswordError: false,
      showNonMatchingPasswordError: false,
      inputPassword: '',
      inputPasswordConfirm: '',
      isPasswordSet: false,
      token: queryParameters.get("token")
    }
  }



  componentDidMount() {
    lookupToken(this.state.token).then(result => {
      this.setState({ isLoading: false })
      if (result !== null) {
        const excludedTitles = [
          "Payments Consultant, UK & Ireland",
          "Field Payments Consultant",
          "PC Associate"
        ]
        const jobTitle = result.data[0].jobTitle
        let fieldUser = excludedTitles.includes(jobTitle)
        let country = result.data[0].extension_91ad418b8de9419d8a3485b4bed2716e_countryCode_2
        let guestUser;
        if (result.data[0].userType === "Guest") {
          guestUser = true;
        }
        this.setState({ country, isGuest: guestUser, isField: fieldUser, isTokenValid: true, maskedSMS: result.data[0].extension_91ad418b8de9419d8a3485b4bed2716e_personalMobile.substring(result.data[0].extension_91ad418b8de9419d8a3485b4bed2716e_personalMobile.length - 3, result.data[0].extension_91ad418b8de9419d8a3485b4bed2716e_personalMobile.length) })
        sendConfirmation(this.state.token);
        //userType: result.data[0].userType, jobTitle: result.data[0].jobTitle, 
      }
    });
  };

  validateSMS = () => {
    this.setState({ showSMSError: false });
    if (document.getElementById("confirmButton").disabled != true) {
      validateConfirmation(this.state.token, this.state.inputSMS).then(result => {
        console.log(this.state.isSMSValid, !this.setState.isPasswordSet)
        if (result) {
          this.setState({ isSMSValid: true, isLoading: true });
          console.log("something something", this.state.isSMSValid, !this.setState.isPasswordSet)
          lookupToken(this.state.token).then(user => {
            console.log(user.data)
            this.setState({ userPrincipalName: user.data[0].userPrincipalName, isLoading: false });
          })
        } else {
          this.setState({ showSMSError: true })
        }

      });
      document.getElementById("confirmButton").disabled = true;
      document.getElementById("confirmButtonLabel").innerHTML = "Validating code, please wait";
      setTimeout(function () {
        document.getElementById("confirmButton").disabled = false;
        document.getElementById("confirmButtonLabel").innerHTML = "Confirm";
      }, 15000);
    }
  };

  smsHandleEvent = (event) => {
    this.setState({ inputSMS: event.target.value })
  }

  resendSMS = () => {
    document.getElementById("resendButton").disabled = true;
    document.getElementById("resendButtonLabel").innerHTML = "SMS resent, please wait";
    setTimeout(function () {
      document.getElementById("resendButton").disabled = false;
      document.getElementById("resendButtonLabel").innerHTML = "Resend SMS";
    }, 15000);
    sendConfirmation(this.state.token).then(result => {
      this.setState({ showResentSMS: true, showSMSError: false });
    })
  }

  passwordHandleEvent = (event) => {
    this.setState({ inputPassword: event.target.value })
  }

  passwordConfirmHandleEvent = (event) => {
    this.setState({ inputPasswordConfirm: event.target.value })
  }

  validatePassword = () => {
    if (document.getElementById("validatingPass").disabled != true) {
      if (this.state.inputPassword === this.state.inputPasswordConfirm) {
        this.setState({ showNonMatchingPasswordError: false });
        setPassword(this.state.token, this.state.inputPassword).then(result => {
          if (result == true) {
            this.setState({ isPasswordSet: true })
          } else {
            this.setState({ showWeakPasswordError: true })
          }
        })
      } else {
        this.setState({ showNonMatchingPasswordError: true });
      }
    }
    document.getElementById("validatingPass").disabled = true;
    document.getElementById("validatingPassLabel").innerHTML = "Validating password, please wait";
    setTimeout(function () {
      document.getElementById("validatingPass").disabled = false;
      document.getElementById("validatingPassLabel").innerHTML = "Submit";
    }, 15000);
  }

  render() {
    let waitMessage;
    let confirmMessage;
    let confirmLabel;
    let copyright;
    let confirmPrimaryButton;
    let confirmSecondaryButton;
    let accountMessagePrimary;
    let accountMessageSecondary;
    let accountHintPrimary;
    let accountHintSecondary;
    let accountLabelPrimary;
    let accountLabelSecondary;
    let accountPasswordNoMatch;
    let accountPasswordWeak;
    let accountButtonPrimary;
    let createdMessagePrimary;
    let createdMessageSecondary;
    let createdLinkGuest;
    let createdLinkField;
    let createdLinkInternal;
    let invalidMessagePrimary;
    let invalidMessageSecondary;

    switch (this.state.country) {
      case 'GB':
        waitMessage = "Please wait - just pulling up the details for your new account"
        confirmMessage = "Before you continue with your Dojo account setup, for security purposes we need to confirm your identity. We've sent an SMS confirmation code to the phone number provided to us during your hiring process. (Ending in ";
        confirmLabel = "Please enter confirmation code"
        copyright = "Copyright 2024 Paymentsense Limited. All rights reserved."
        confirmPrimaryButton = "Confirm"
        confirmSecondaryButton = "Resend SMS"
        accountMessagePrimary = "Your Dojo account is:"
        accountMessageSecondary = "To finish setting up your account, you'll need to set a secure password. Your password should:"
        accountHintPrimary = "Contain 8 or more characters"
        accountHintSecondary = "Contain a mixture of numbers, symbols, upper and lower case letters"
        accountLabelPrimary = "Password"
        accountLabelSecondary = "Confirm password"
        accountPasswordNoMatch = "Sorry, your passwords don't appear to match. Please try again."
        accountPasswordWeak = "Sorry, your password easily guessible or has been seen too many times. Please try again."
        accountButtonPrimary = "Submit"
        createdMessagePrimary = "Your Dojo account is:"
        createdMessageSecondary = "You can now log into your account with the password you've just set."
        createdLinkGuest = "Sign in and access applications"
        createdLinkField = "Sign in and access training"
        createdLinkInternal = "Sign in and complete tasks in Workday"
        invalidMessagePrimary = "Sorry, it appears that link is invalid."
        invalidMessageSecondary = "If you continue to have problems, please contact Sales Support."
        break;
      case 'IE':
        waitMessage = "Please wait - just pulling up the details for your new account"
        confirmMessage = "Before you continue with your Dojo account setup, for security purposes we need to confirm your identity. We've sent an SMS confirmation code to the phone number provided to us during your hiring process. (Ending in ";
        confirmLabel = "Please enter confirmation code"
        copyright = "Copyright 2024 Paymentsense Limited. All rights reserved."
        confirmPrimaryButton = "Confirm"
        confirmSecondaryButton = "Resend SMS"
        accountMessagePrimary = "Your Dojo account is:"
        accountMessageSecondary = "To finish setting up your account, you'll need to set a secure password. Your password should:"
        accountHintPrimary = "Contain 8 or more characters"
        accountHintSecondary = "Contain a mixture of numbers, symbols, upper and lower case letters"
        accountLabelPrimary = "Password"
        accountLabelSecondary = "Confirm password"
        accountPasswordNoMatch = "Sorry, your passwords don't appear to match. Please try again."
        accountPasswordWeak = "Sorry, your password easily guessible or has been seen too many times. Please try again."
        accountButtonPrimary = "Submit"
        createdMessagePrimary = "Your Dojo account is:"
        createdMessageSecondary = "You can now log into your account with the password you've just set."
        createdLinkGuest = "Sign in and access applications"
        createdLinkField = "Sign in and access training"
        createdLinkInternal = "Sign in and complete tasks in Workday"
        invalidMessagePrimary = "Sorry, it appears that link is invalid."
        invalidMessageSecondary = "If you continue to have problems, please contact Sales Support."
        break;
      case 'ES':
        waitMessage = "Espere, simplemente acceda a los detalles de su nueva cuenta"
        confirmMessage = "Antes de continuar con la configuración de su cuenta Dojo, por motivos de seguridad necesitamos confirmar su identidad. Le hemos enviado un código de confirmación por SMS al número de teléfono que nos proporcionó durante su proceso de contratación. (Termina en ";
        confirmLabel = "Por favor ingrese el código de confirmación"
        copyright = "Copyright 2024 Paymentsense Limited. Reservados todos los derechos."
        confirmPrimaryButton = "Confirmar"
        confirmSecondaryButton = "Reenviar SMS"
        accountMessagePrimary = "Tu cuenta Dojo es:"
        accountMessageSecondary = "Para terminar de configurar su cuenta, deberá establecer una contraseña segura. Su contraseña debe:"
        accountHintPrimary = "Contiene 8 o más caracteres"
        accountHintSecondary = "Contiene una mezcla de números, símbolos, letras mayúsculas y minúsculas"
        accountLabelPrimary = "Contraseña"
        accountLabelSecondary = "Confirmar contraseña"
        accountPasswordNoMatch = "Lo sentimos, sus contraseñas no parecen coincidir. Por favor inténtalo de nuevo."
        accountPasswordWeak = "Lo sentimos, tu contraseña se puede adivinar fácilmente o se ha visto demasiadas veces. Por favor inténtalo de nuevo."
        accountButtonPrimary = "Entregar"
        createdMessagePrimary = "Tu cuenta Dojo es:"
        createdMessageSecondary = "Ahora puede iniciar sesión en su cuenta con la contraseña que acaba de establecer."
        createdLinkGuest = "Iniciar sesión y acceder a las aplicaciones"
        createdLinkField = "Inicia sesión y accede a la formación"
        createdLinkInternal = "Iniciar sesión y completar tareas en Workday"
        invalidMessagePrimary = "Lo sentimos, parece que el enlace no es válido."
        invalidMessageSecondary = "Si continúa teniendo problemas, comuníquese con el Soporte de ventas."
        break;
      case 'IT':
        waitMessage = "Per favore attendi: sto recuperando i dettagli per il tuo nuovo account"
        confirmMessage = "Prima di continuare con la configurazione del tuo account Dojo, per motivi di sicurezza dobbiamo confermare la tua identità. Abbiamo inviato un codice di conferma via SMS al numero di telefono fornitoci durante il processo di assunzione. (Finisce con ";
        confirmLabel = "Inserisci il codice di conferma"
        copyright = "Copyright 2024 Paymentsense Limited. Tutti i diritti riservati."
        confirmPrimaryButton = "Confermare"
        confirmSecondaryButton = "Invia nuovamente SMS"
        accountMessagePrimary = "Il tuo account Dojo è:"
        accountMessageSecondary = "Per completare la configurazione del tuo account, dovrai impostare una password sicura. La tua password dovrebbe:"
        accountHintPrimary = "Contain 8 or more characters"
        accountHintSecondary = "Contenere 8 o più caratteri"
        accountLabelPrimary = "Password"
        accountLabelSecondary = "Conferma password"
        accountPasswordNoMatch = "Siamo spiacenti, le tue password non sembrano corrispondere. Per favore riprova."
        accountPasswordWeak = "Siamo spiacenti, la tua password è facilmente indovinabile o è stata vista troppe volte. Per favore riprova.in."
        accountButtonPrimary = "Invia"
        createdMessagePrimary = "Il tuo account Dojo è:"
        createdMessageSecondary = "Ora puoi accedere al tuo account con la password che hai appena impostato."
        createdLinkGuest = "Accedi e accedi alle applicazioni"
        createdLinkField = "Accedi e accedi alla formazione"
        createdLinkInternal = "Accedi e completa le attività in Workday"
        invalidMessagePrimary = "Siamo spiacenti, sembra che il collegamento non sia valido."
        invalidMessageSecondary = "Se continui ad avere problemi, contatta il supporto alle vendite."
        break;
      // Add more cases for other countries as needed
      default:
        waitMessage = "Please wait - just pulling up the details for your new account"
        confirmMessage = "Before you continue with your Dojo account setup, for security purposes we need to confirm your identity. We've sent an SMS confirmation code to the phone number provided to us during your hiring process. (Ending in ";
        confirmLabel = "Please enter confirmation code"
        copyright = "Copyright 2024 Paymentsense Limited. All rights reserved."
        confirmPrimaryButton = "Confirm"
        confirmSecondaryButton = "Resend SMS"
        accountMessagePrimary = "Your Dojo account is:"
        accountMessageSecondary = "To finish setting up your account, you'll need to set a secure password. Your password should:"
        accountHintPrimary = "Contain 8 or more characters"
        accountHintSecondary = "Contain a mixture of numbers, symbols, upper and lower case letters"
        accountLabelPrimary = "Password"
        accountLabelSecondary = "Confirm password"
        accountPasswordNoMatch = "Sorry, your passwords don't appear to match. Please try again."
        accountPasswordWeak = "Sorry, your password easily guessible or has been seen too many times. Please try again."
        accountButtonPrimary = "Submit"
        createdMessagePrimary = "Your Dojo account is:"
        createdMessageSecondary = "You can now log into your account with the password you've just set."
        createdLinkGuest = "Sign in and access applications"
        createdLinkField = "Sign in and access training"
        createdLinkInternal = "Sign in and complete tasks in Workday"
        invalidMessagePrimary = "Sorry, it appears that link is invalid."
        invalidMessageSecondary = "If you continue to have problems, please contact Sales Support."
    }

    if (this.state.isLoading) {

      return (
        <div class='module2'>
          <div class='fields1'>
            <div class='loremIpsumDolorSitAmetConsecte'>
              <p>{waitMessage}</p>
            </div>
          </div>
          <br />
          <div class='copyright'>
            <div class='copyright2023PaymentsenseLimte'>
              {copyright}
            </div>
          </div>
        </div>

      )
    }
    if (this.state.isTokenValid && !this.state.isSMSValid) {

      return (
        <div class='module2'>
          <div class="fields1">
            <div class="loremIpsumDolorSitAmetConsecte"><p>{confirmMessage}{this.state.maskedSMS})</p></div>
            <div class="fields2">
              <div class="clapyResets root3">
                <div class="textHint">
                  <div class="label">{confirmLabel}</div>
                </div>
                <div class="inputFieldDefault">
                  <input class='inputFieldText' onChange={this.smsHandleEvent} />
                  <div class="label2"></div>
                </div>
              </div>
              <br />
              <button id='confirmButton' class="clapyResets cTAs root5" onClick={this.validateSMS}>
                <div id='confirmButtonLabel' class="getStarted">{confirmPrimaryButton}</div>
              </button>
              <br />
              <button id='resendButton' class="clapyResets cTAs2 root6" onClick={this.resendSMS}>
                <div id='resendButtonLabel' class="getStarted2">{confirmSecondaryButton}</div>
              </button>
            </div>
          </div>
          <br />
          <div class='copyright'>
            <div class='copyright2023PaymentsenseLimte'>
              {copyright}
            </div>
          </div>
        </div>
      )
    }
    if (this.state.isSMSValid && !this.state.isPasswordSet) {

      return (
        <div class='module2'>
          <div class="fields1">
            <div class="intro">
              <div class="loremIpsumDolorSitAmetConsecte"><p>{accountMessagePrimary} <b>{this.state.userPrincipalName}</b></p>
                <p>{accountMessageSecondary}</p>
                <p>
                  <b>-</b> {accountHintPrimary}<br />
                  <b>-</b> {accountHintSecondary}
                </p>
              </div>
            </div>
            <div class="fields2">
              <div class="clapyResets root3">
                <div class="textHint">
                  <div class="label">{accountLabelPrimary}</div>
                </div>
                <div class="inputFieldDefault">
                  <input class='inputFieldText' type='password' onChange={this.passwordHandleEvent} />
                  <div class="label2"></div>
                </div>
              </div>
              <br />
              <div class="clapyResets root3">
                <div class="textHint">
                  <div class="label">{accountLabelSecondary}</div>
                </div>
                <div class="inputFieldDefault">
                  <input class='inputFieldText' type='password' onChange={this.passwordConfirmHandleEvent} />
                  <div class="label2"></div>
                </div>
              </div>
              <br />
              <div hidden={!this.state.showNonMatchingPasswordError} style={{ color: "rgb(255,0,0)" }}><p>{accountPasswordNoMatch}</p></div>
              <div hidden={!this.state.showWeakPasswordError} style={{ color: "rgb(255,0,0)" }}><p>{accountPasswordWeak}</p></div>
              <button id="validatingPass" class="clapyResets cTAs root5" onClick={this.validatePassword}>
                <div id="validatingPassLabel" class="getStarted">{accountButtonPrimary}</div>
              </button>
              <br />
            </div>
          </div>
          <br />
          <div class='copyright'>
            <div class='copyright2023PaymentsenseLimte'>
              {copyright}
            </div>
          </div>
        </div>
      )
    }

    if (this.state.isPasswordSet && this.state.isGuest) {

      return (
        <div class='module2'>
          <div class='fields1'>
            <div class='loremIpsumDolorSitAmetConsecte'>
              <p>{createdMessagePrimary} <b>{this.state.userPrincipalName}</b></p>
              <p>{createdMessageSecondary}</p>
              <a href={"https://myapps.microsoft.com/?login_hint=" + this.state.userPrincipalName}>{createdLinkGuest}</a>
            </div>
          </div>
          <br />
          <div class='copyright'>
            <div class='copyright2023PaymentsenseLimte'>
              {copyright}
            </div>
          </div>
        </div>
      )
    }

    if (this.state.isPasswordSet && this.state.isField) {

      return (
        <div class='module2'>
          <div class='fields1'>
            <div class='loremIpsumDolorSitAmetConsecte'>
              <p>{createdMessagePrimary} <b>{this.state.userPrincipalName}</b></p>
              <p>{createdMessageSecondary}</p>
              <a href={"https://myapps.microsoft.com/?login_hint=" + this.state.userPrincipalName}>{createdLinkField}</a>
            </div>
          </div>
          <br />
          <div class='copyright'>
            <div class='copyright2023PaymentsenseLimte'>
              {copyright}
            </div>
          </div>
        </div>
      )
    }

    if (this.state.isPasswordSet && !this.state.isField && !this.state.isGuest) {

      return (
        <div class='module2'>
          <div class='fields1'>
            <div class='loremIpsumDolorSitAmetConsecte'>
              <p>{createdMessagePrimary} <b>{this.state.userPrincipalName}</b></p>
              <p>{createdMessageSecondary}</p>
              <a href="https://wd3.myworkday.com/paymentsense/d/task/14860$26.htmld">{createdLinkInternal}</a>
            </div>
          </div>
          <br />
          <div class='copyright'>
            <div class='copyright2023PaymentsenseLimte'>
              {copyright}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div class='module2'>
        <div class='fields1'>
          <div class='loremIpsumDolorSitAmetConsecte'>
            <p>{invalidMessagePrimary}</p>
            <p>{invalidMessageSecondary}</p>
          </div>
        </div>
        <br />
        <div class='copyright'>
          <div class='copyright2023PaymentsenseLimte'>
            {copyright}
          </div>
        </div>
      </div>
    )
  }
}

export default App;
